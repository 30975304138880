<template>
	<div>
	<a-breadcrumb class="mb-10">
			<a-breadcrumb-item><router-link to="/">Ordenes</router-link></a-breadcrumb-item>
			<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
	</a-breadcrumb>
	<a-card :bordered="false" class="header-solid h-full mb-24" :bodyStyle="{paddingTop: '14px'}">
		<template #title>
			<h6 class="font-semibold">Ordenes</h6>			
			<p>Todos mis pedidos</p>	
		</template>
	   <a-table :columns="columns" :data-source="data" :pagination="false">
				<template slot="productName" slot-scope="productName">
					<div class="author-info">
						<h6 class="m-0">{{ productName }}</h6>		
					</div>
				</template>
				<template slot="dateCreated" slot-scope="dateCreated">
					<div class="author-info">
						<h6 class="m-0">{{ dateCreated| date  }}</h6>	
					</div>
				</template>
				<template slot="status" slot-scope="status">
					<a-tag class="tag-status" :class="getColorbyStatus(status)">
						{{ status}}
					</a-tag>
				</template>
				<template slot="editBtn" slot-scope="row">
				<router-link :to="`/ordersdetail/${row.id}`" class="btn-sign-in">	
					<a-button type="link" :data-id="row.key" class="btn-edit">
						VER
					</a-button>
					</router-link>	
				</template>
			
		</a-table>
	</a-card>
	</div>
</template>

<script>
import  config from '@/utils/table.config'
import { OrderStatusClient } from '@/utils/orderStatus'
	// "Authors" table component.
const columns = config.ordersColumns;
import { getOrderById } from '@/services/orders/orders';
import { isLogin} from '@/services/auth/auth';
	const data = [];
	export default ({
		components: {

		},
		data() {
			return {
				columns,
				data
			}
		},

		created(){
			this.getOrders()
		},
		destroyed() {
    		this.data = []
  		},
		methods:{
			getOrders(){
				isLogin((user)=>{
					if(user){
						getOrderById((data)=>{
							this.data=data
						},user.uid)
					}else{
						this.$router.push('/sign-in');
					}
			   })		
			},
			formattedDate(data) {
				const date = new Date(data);
				return date.toString(); // Puedes personalizar el formato de fecha según tus necesidades
     		},
			getColorbyStatus(status){
				switch (status) {
				case OrderStatusClient.ENTREGADO:
					return 'status-recived'
					break;
				case OrderStatusClient.DISENO:
					return 'status-design'
					break;
				case OrderStatusClient.TRABAJANDO:
					return 'status-working'
					break;
				case OrderStatusClient.FINALIZADO:
					return 'status-end'
					break;			
				}
			}
		}
	})

</script>

<style lang="scss" scope>
	.status-recived{
		background-color: #FADB14;
		color: #ffffff;
		border: none;
	}
	.status-design{
		background-color: gray;
		color: #ffffff;
		border: none;
	}
	.status-working{
		background-color: orange;
		color: #ffffff;
		border: none;
	}
	.status-end{
		background-color: #42ba96;
		color: #ffffff;
		border: none;
	}
</style>